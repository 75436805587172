const style = {
    consent: {
        margin: 0,
        '& .MuiFormControlLabel-label': {
            fontSize: '12px',
            lineHeight: 'normal'
        }
    },
    checkbox: {
        margin: 0
    }
}

export default style;
