import Contact from 'components/Contact';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import theme from './theme';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { de } from 'date-fns/locale'

export default function App() {
	return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={de}>
                        <Contact />
                    </LocalizationProvider>
                </ThemeProvider>
        </StyledEngineProvider>
    );
}
