import { useState, FunctionComponent } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Button, FormControlLabel, Checkbox, CircularProgress } from '@mui/material';
import { Alert, AlertTitle } from '@mui/material';
import Field from './Field';
import style from './Form.style';
import { createUseStyles } from 'react-jss';
import { IFieldSettings, IForm, IMessage, IFormFields } from 'constants/interfaces';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Overview from './Overview';
import { client } from '../../graphql';
import { gql } from 'graphql-request';
import { useEffect } from 'react';


const useStyles = createUseStyles(style);
const delay = (ms:number) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}
const serverError = 'Leider konnte der Server nicht erreicht werden. Bitte versuchen Sie es zu einem späteren Zeitpunkt noch einmal oder kontaktieren Sie uns direkt.'

const Form: FunctionComponent<IForm> = ({formInfo}) => {
  const classes = useStyles();
  const [checked, setChecked] = useState<boolean>(false);
  const [consentAccepted, setConsentAccepted] = useState<boolean>(false);
  const [message, setMessage] = useState<null | IMessage>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [Icon, setIcon] = useState<any>();
  
  const [activeStep, setActiveStep] = useState<number>(JSON.parse(localStorage.getItem("webinar_plan_step") || '0'));
  const { control, trigger, getValues, formState: { errors }, handleSubmit } = useForm({
    mode: 'onBlur',
  });

  const onSubmit = (data:any) => {
    data.location = window.location.href;


    const submitContact = async (subTry:number) => {
      try {
        let requestData = await client.request(gql`
          mutation submitContact(
            $id: String!,
            $request: jsonb
          ) {
            submitContactForm(id: $id, request: $request){
              status
            }
          }
        `, {id: formInfo.id, request: JSON.stringify(data)});
        if(requestData.submitContactForm.status && requestData.submitContactForm.status.success) {
          setMessage({title: 'Daten wurden erfolgreich versandt!', type: 'success', msg: 'Vielen Dank für Ihre Nachricht! Wir werden uns in Kürze bei Ihnen melden.'});
          setLoading(false);
          localStorage.removeItem("webinar_plan");
          localStorage.removeItem("webinar_plan_step");
        } else {
          setMessage({title: 'Server-Fehler', msg: serverError, type: 'error'});
          setLoading(false);
        }
      } catch (err) {
        delay(3000 * subTry).then(() => {
          if (subTry < 5){
            submitContact(subTry+1)
          } else {
            setMessage({title: 'Verbindungsfehler', msg: serverError, type: 'error'});
            setLoading(false);
          }
        })
        console.log(err)
      }
    }
    console.log(data);
    setLoading(true);
    submitContact(1);
  };

  const saveValues = (step:number) => {
    let values = getValues();
    Object.keys(values).forEach(key => values[key] === undefined && delete values[key]);
    localStorage.setItem("webinar_plan_step",step.toString());
    localStorage.setItem("webinar_plan",JSON.stringify(values));
  }


  const handleStep = async (step:number) => {
    setLoading(true);
    let valid = true;
    let stepFields = formInfo.fields.filter(f => f.step === step).map(f => f.id);
    for (const name of stepFields) {
      const res = await trigger(name);
      if (res === false) {
        valid = false;
      }
    }

    if (valid) {
      window.scrollTo(0, 0);
      setActiveStep(step + 1);
      saveValues(step);
      setLoading(false);
    } else {
      setLoading(false);
    }
 
  }

  useEffect(() => {
    let newIcon = (formInfo && formInfo.steps) ? formInfo.steps.find(s => s.id === activeStep).icon : null;
    setIcon(newIcon);
  },[activeStep, formInfo])


  return (
    <div className={classes.formWrapper}>
      {formInfo.showTitle && <h1 style={{margin: '24px 0', fontSize: '170%', textAlign: 'center'}}>{formInfo.title}</h1>}
      {formInfo.steps && activeStep > 0 && <Stepper activeStep={activeStep-1}>
          {formInfo.steps.filter(s => !s.intro).map((step, index) => (
            <Step key={'step-'+step.id} completed={activeStep > step.id} disabled={loading || activeStep <= step.id}>
              <StepButton
                onClick={() => setActiveStep(step.id)} />
            </Step>
          ))}
        </Stepper>}
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        {formInfo.steps && <h2 className={classes.headline}>{Icon && <Icon />} {formInfo.steps[activeStep].title}</h2>}
        {message && <Alert severity={message.type} style={{marginBottom: '24px'}}>
          <AlertTitle>{message.title}</AlertTitle>
          {message.msg}
        </Alert>}
        {(!message || (message && message.type !== 'success')) && <>
          {formInfo && (formInfo.steps ? formInfo.fields.filter(f => f.step === activeStep) : formInfo.fields).map((f:IFormFields, idx:number) => {
            if (f.id){
              let fieldRules:IFieldSettings = {
                required: f.required ? 'Dieses Feld muss ausgefüllt werden.' : undefined,
                maxLength: f.max ? {
                  value: f.max,
                  message: 'Dieses Feld darf maximal ' + f.max + ' Zeichen lang sein.'} : undefined,
                minLength: f.min ? {
                  value: f.min,
                  message: 'Dieses Feld muss mindestens ' + f.min + ' Zeichen lang sein.'} : undefined,
                pattern: f.type === 'email' ? {
                  value: /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/i,
                  message: 'Ungültige E-Mail Adresse.'
                } : undefined
              };
              return <div className={classes.field} key={'fld-'+activeStep+'-'+idx}>
                {f.type === 'callback' && f.consent && <FormControlLabel
                  labelPlacement="end"
                  disabled={loading}
                  control={<Checkbox disabled={loading} style={{margin: '-8px 0 auto 4px'}} checked={checked} onChange={() => setChecked(!checked)} name={'cb_'+f.id} />}
                  label={f.consent.text}
                  className={classes.checkboxCallback}
                />}
                {(f.type !== 'callback' || (f.type === 'callback' && checked)) &&
                <Controller
                  control={control}
                  rules={fieldRules}
                  name={f.id}
                  defaultValue={(JSON.parse(localStorage.getItem("webinar_plan") || '{}') && JSON.parse(localStorage.getItem("webinar_plan") || '{}')[f.id]) || ''}
                  render={({ field }) => {
                    const {ref, ...fieldProps} = field;

                    return <Field 
                      id={f.id + '-' + activeStep + '-' + idx}
                      fieldData={f}
                      inputRef={ref}
                      error={(f.id && errors[f.id]) ? true : false}
                      helperText={(f.id && errors[f.id]) ? errors?.[f.id]?.message : f.info ? f.info : null}
                      disabled={loading}
                      required={f.required}
                      {...fieldProps}
                      {...formInfo.formFieldProps} />
                  }} />}
                {f.consent && f.consent.links && <>{f.consent.linksTitle && <h5 className={classes.consentLinkHl}>{f.consent.linksTitle}</h5>}<ul className={classes.consentLinks}>{f.consent.links.map((link,idx) => <li key={'co-lnk-'+activeStep+'-'+idx}><a target="_blank" rel="noopener noreferrer" href={link.url}>{link.title ? link.title : link.url}</a></li>)}</ul></>}
              </div>
            } else if (f.content) {
              return <div key={'fld-'+activeStep+'-'+idx} style={{margin: '16px'}}>{f.content}</div>
            } else if (f.type === 'overview') {
              let Icon = f.icon;
              return <div key={'fld-'+activeStep+'-'+idx}>
                <h2>{f.icon && <Icon />} {f.title}</h2>
                <Overview values={getValues()} fields={formInfo.fields} steps={formInfo.steps} />
              </div>
            } else {
              return null
            }
          })}
          {(!formInfo.steps || formInfo.steps.length - 1 === activeStep) ?
          <div>
            {formInfo && formInfo.consent &&  <>
              <FormControlLabel
                labelPlacement="end"
                disabled={loading}
                style={{fontSize: '90%'}}
                control={<Checkbox required={true} style={{margin: '-8px 0 auto 4px'}} checked={consentAccepted} onChange={() => setConsentAccepted(!consentAccepted)} name="consent" />}
                label={formInfo.consent.text}
                className={classes.consent}
              />
              {formInfo.consent.links !== undefined && <>{formInfo.consent.linksTitle && <h5 className={classes.consentLinkHl}>{formInfo.consent.linksTitle}</h5>}<ul className={classes.consentLinks}>{formInfo.consent.links.map((link,idx) => <li key={'co-lnk-'+activeStep+'-'+idx}><a target="_blank" rel="noopener noreferrer" href={link.url}>{link.title ? link.title : link.url}</a></li>)}</ul></>}
            </>}
            <div style={{margin: '16px 0'}}>
              <Button
                disabled={!consentAccepted || loading}
                fullWidth
                variant="contained"
                color="primary"
                type="submit"
                className={classes.btn}
                style={consentAccepted ? {backgroundColor: 'rgba(' + (formInfo ? formInfo.color : '46,46,46') + ',.8)'} : {}}>
                {loading ? <CircularProgress style={{width: '24px', height: '24px', color: 'rgba('+formInfo.color+',1)'}} /> : ((formInfo && formInfo.submitTxt) || 'Anfrage senden')}
              </Button>
            </div>
            <div style={{fontSize: '12px'}}>
              {formInfo && formInfo.consent && !consentAccepted && 
              <p style={{textAlign: 'center', color: 'rgba(226,6,19,.25)'}}>
                <ErrorOutlineIcon style={{height: '10px'}} />
                {formInfo.consent.notaccepted ? formInfo.consent.notaccepted : 'Das Abschicken dieses Formulars erfordert die Einwilligung unser Compliance-Richtlinien.'}
              </p>}
            </div>
          </div> : <>
          {(formInfo.steps[activeStep].next || formInfo.steps[activeStep].nextTxt) && <div style={{marginBottom: '16px'}}>
              <Button
                disabled={loading || Object.keys(errors).length > 0}
                fullWidth
                onClick={() => handleStep(activeStep)}
                variant="contained"
                color="primary"
                type="button"
                className={classes.btn}
                style={{}}>
                {formInfo.steps[activeStep].nextTxt || 'Weiter'}
              </Button>
            </div>}
          </>}
          {formInfo.steps && (formInfo.steps[activeStep].back || formInfo.steps[activeStep].backTxt) && <div style={{textAlign: 'center'}}>
              <Button
                disabled={loading}
                onClick={() => setActiveStep(activeStep - 1)}
                variant="text"
                color="secondary"
                className={classes.btnBk}
                style={{color: '#555'}}>
                {formInfo.steps[activeStep].backTxt || 'Zurück'}
              </Button>
            </div>}
        </>}
        <div style={{fontSize: '12px'}}>
          {(errors && Object.keys(errors).length > 0) && 
          <p style={{textAlign: 'center', color: 'rgba(226,6,19,.25)'}}>
            <ErrorOutlineIcon style={{height: '10px'}} />
            Ungültige Eingabe: Bitte überprüfen Sie Ihre Angaben im Formular.
          </p>}
        </div>
      </form>
    </div>
  );


}

export default Form;
