import { FunctionComponent } from 'react';
import { IFormOption } from 'constants/interfaces';
import { TextField, FormControlLabel, FormControl, Checkbox, FormHelperText, MenuItem, Radio } from '@mui/material';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {TimePicker} from '@mui/x-date-pickers/TimePicker';
import { createUseStyles } from 'react-jss';
import style from './Field.style';
import EventPicker from './Fields/EventPicker';


const useStyles = createUseStyles(style);


const Field: FunctionComponent<any> = ({fieldData, variant, id, helperText, value, name, onChange, inputRef, InputLabelProps, required}) => {
  const classes = useStyles();
  const {type, consent, title, placeholder, options, calId, fieldProps} = fieldData;

  switch (type) {
    case 'checkbox':
      return <FormControl
        component='fieldset'>
          <FormControlLabel
              className={consent ? classes.consent : classes.checkbox}
              control={<Checkbox inputRef={inputRef} style={{margin: '-8px 0 auto 4px'}} checked={Boolean(value)} required={required} onChange={onChange} name={name} {...fieldProps}/>}
              label={consent ? consent.text : title}
            />
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    case 'textarea': 
      return <TextField
        rows={6}
        placeholder={placeholder}
        multiline={true}
        label={title}
        variant={variant}
        helperText={helperText}
        onChange={onChange}
        name={name}
        fullWidth
        inputRef={inputRef}
        value={value}
        required={required}
        InputLabelProps={InputLabelProps}
        {...fieldProps}
        />
    case 'event':
      return <EventPicker
              onChange={onChange}
              calId={calId}
            />
    case 'date':
      return <DatePicker
      inputFormat='dd.MM.yyyy'
      renderInput={(props: any) => (
        <TextField {...props} helperText={helperText} placeholder={placeholder} fullWidth variant={variant} label={title} required={required} InputLabelProps={InputLabelProps} {...fieldProps} />
      )}
      onChange={onChange}
      value={value}
      inputRef={inputRef} />
    case 'radio': 
        return <FormControlLabel
        {...fieldProps}
        control={<Radio />}
        label={title}
        labelPlacement="end"
      />
    case 'time':
      return <TimePicker
      ampm={false}
      inputFormat='HH:mm'
      renderInput={(props:any) => (
        <TextField {...props} helperText={helperText} fullWidth placeholder={placeholder} variant={variant} label={title} required={required} InputLabelProps={InputLabelProps} {...fieldProps} />
      )}
      onChange={onChange}
      value={value}
      inputRef={inputRef} />
    case 'select':
      return <TextField
        placeholder={placeholder}
        select={true}
        label={title}
        variant={variant}
        helperText={helperText}
        onChange={onChange}
        name={name}
        value={value}
        fullWidth
        inputRef={inputRef}
        InputLabelProps={InputLabelProps}
        {...fieldProps}
        required={required}>
          {options && options.map((option:IFormOption) => <MenuItem key={option.id} value={option.id}>{option.title}</MenuItem>)}
        </TextField>
    default:
      return <TextField
        placeholder={placeholder}
        label={title}
        variant={variant}
        helperText={helperText}
        onChange={onChange}
        name={name}
        value={value}
        fullWidth
        inputRef={inputRef}
        InputLabelProps={InputLabelProps}
        {...fieldProps}
        required={required}>
          {options && options.map((option:IFormOption) => <MenuItem key={option.id} value={option.id}>{option.title}</MenuItem>)}
        </TextField>
  }

}

export default Field;
