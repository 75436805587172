

import { FunctionComponent, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import style from './EventPicker.style';
import { CalendarPicker } from '@mui/x-date-pickers/CalendarPicker';
import { Button, CircularProgress, List, ListItem, ListItemButton, ListItemIcon, ListSubheader } from '@mui/material';
import { format, addYears, isSameDay } from 'date-fns/esm';
import CalendarEventIcon from '@mui/icons-material/Today';
import CalendarEventSelectedIcon from '@mui/icons-material/EventAvailable';
import { gql } from 'graphql-request';
import { client } from '../../../graphql';
import { IEvent } from 'constants/interfaces';
import { de } from 'date-fns/locale';


const useStyles = createUseStyles(style);

const EventItem: FunctionComponent<any> = ({item, selectedEvent, setSelectedEvent}) => {
  const classes = useStyles();
  return <ListItem className={classes.listItem}>
  <ListItemButton className={selectedEvent === item.id ? classes.eventSelected : classes.event} onClick={() => setSelectedEvent(selectedEvent !== item.id ? item.id : null)}>
    <ListItemIcon className={classes.listIcon}>
      {selectedEvent === item.id ? <CalendarEventSelectedIcon /> : <CalendarEventIcon />}
    </ListItemIcon>
    <div className={classes.listItemContent}>
      <ListSubheader className={classes.listHead}>{format(item.start, 'PPPP', {locale: de})}</ListSubheader>
      <div className={classes.listText}>{(item.title ? item.title + ' ' : '') + format(item.start, 'HH:mm') + ' - ' + format(item.end, 'HH:mm')}</div>
    </div>
  </ListItemButton>
</ListItem>
}

const EventPicker: FunctionComponent<any> = ({calId, onChange}) => {
  const classes = useStyles();

  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [events, setEvents] = useState<[IEvent] | null>(null);
  const [selectedEvent, setSelectedEvent] = useState<String | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);

  const onSelectEvent = (event: String | null) => {
    setSelectedEvent(event);
    onChange(event);
  }

  useEffect(() => {
    const getEvents = async () => {
      try {
        let calData = await client.request(gql`
        {
          calendars_by_pk(
            id: "${calId}"
          ) {
            events {
              id
              title
              start
              end
            }
          }
        }
        `);
        if(calData.calendars_by_pk.events?.length > 0){
          setEvents(calData.calendars_by_pk.events.map((e:IEvent) => ({
            ...e,
            start: new Date(e.start),
            end: new Date(e.end),
          })).sort((a: IEvent,b: IEvent) => a.start.getTime()-b.start.getTime()));
          setLoading(false);
        } else {
          setError(true);
          setLoading(false);
        }
      } catch (e) {
        console.log(e);
      }
    };
    getEvents();
  },[calId])


  return selectedEvent ? <>
      <div className={classes.wrapper}>
        <h3 className={classes.hl}>Termin</h3>
        <Button className={classes.btn} variant="outlined" onClick={() => onSelectEvent(null)}>Anderen Termin wählen</Button>
      </div>
      <List>
        <EventItem item={events?.find(e => e.id === selectedEvent)} selectedEvent={selectedEvent} setSelectedEvent={onSelectEvent} />
      </List>

    </> : <>
    <h3 className={classes.hl}>Termin wählen</h3>

    <div className={classes.pickerWrapper}>
      <div>
        <CalendarPicker
          openTo="day"
          views={['day']}
          loading={loading || error}
          disabled={loading || error}
          renderLoading={() => error ? <>Keine Termine vorhanden</> : <CircularProgress />}
          date={selectedDate}
          reduceAnimations
          shouldDisableDate={(date:Date) => date ? !events?.find(e => isSameDay(e.start, date)) : true}
          minDate={new Date()}
          maxDate={addYears(new Date(), 1)}
          onChange={(date:any) => {if (date) setSelectedDate(date)}} />
        </div>
        <div className={classes.slots}>
          <h5>{selectedDate ? 'Termine am ' + format(selectedDate, 'dd. MMM yyyy', {locale: de}) : 'Die nächsten Termine'}</h5>
            <nav aria-label="main mailbox folders">
            <List>
              {events?.filter((e, idx) => selectedDate ? isSameDay(e.start, selectedDate) : idx < 4).map(e => <EventItem item={e} selectedEvent={selectedEvent} setSelectedEvent={onSelectEvent} />)}
            </List>
          </nav>
        </div>
      </div>
    </>
}

export default EventPicker;