import { gql } from 'graphql-request';

const getAvatarsQuery = gql`
query getAvatars(
  $ids: jsonb
) {
  getAvatars(ids: $ids) {
    res
  }
}
`;

export default getAvatarsQuery;