import {formData} from 'constants/forms';
import TemplateMain from 'components/Templates/TemplateMain';
// import TemplateAcademy from 'components/Templates/TemplateAcademy';

declare global {
  interface Window {
      formId:string;
  }
}

const id = window.formId || 'default';
const formInfo = formData.find(f => f.id === id) || formData.find(f => f.id === 'default');

const Contact = () => {

  return <TemplateMain formInfo={formInfo} />
  // switch(formInfo?.template){
  //   case 'TemplateAcademy':
  //     return <TemplateAcademy formInfo={formInfo} avatarsData={avatarsData} />
  //   default: 
  //     return <TemplateMain formInfo={formInfo} avatarsData={avatarsData} />
  // }
};

export default Contact;
