import { IFormData } from 'constants/interfaces';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PermDataSettingIcon from '@mui/icons-material/PermDataSetting';

const members = {
  mg: {name: 'Matthias Gritsch', position: 'Geschäftsführer zimtnow GmbH', email: 'm.gritsch@zimtnow.com', avatar: '8a40ca02-ebad-4f73-bbfc-fc66deccc63e', company: 'zimtnow GmbH'},
  zf: {name: 'Matthias Gritsch', position: 'Inhaber zimtfilm e.U.', email: 'm.gritsch@zimtfilm.com', avatar: '8a40ca02-ebad-4f73-bbfc-fc66deccc63e', company: 'zimtfilm e.U.'},
  ee: {name: 'Emanuel Ebner', position: 'Art Director, Projektmanagement', email: 'e.ebner@zimtnow.com', avatar: '172ac04f-ca8c-4f27-b10e-daed9c296ec1', company: 'zimtnow GmbH'},
  mm: {name: 'Martin Mackinger', position: 'Postproduktion', email: 'm.mackinger@zimtnow.com', avatar: '27cc109f-bdd6-4d99-a5dd-709302743439', company: 'zimtnow GmbH'},
  // jb: {name: 'Julia Brugger', position: 'Administration, Finanzen, HR', email: 'j.brugger@zimtnow.com', avatar: '6d73d94f-8f75-4b95-a560-eba4e0d98100', company: 'zimtnow GmbH'},
  // tr: {name: 'Thaddäus Reich', position: 'Projektmanagement Digital', email: 't.reich@zimtnow.com', avatar: '4179655b-5e33-4740-83fb-0e630a05f8c3', company: 'zimtnow GmbH'},
  // ds: {name: 'David Sommeregger', position: 'Webinar Operator', email: 'd.sommeregger@zimtnow.com', avatar: '98a717e9-d945-4055-a3a7-833edba8c13b', company: 'zimtnow GmbH'},
  // jbzf: {name: 'Julia Brugger', position: 'Administration, Finanzen, HR', email: 'office@zimtfilm.com', avatar: '6d73d94f-8f75-4b95-a560-eba4e0d98100', company: 'zimtfilm e.U.'},
}

const defaultForm = {
  title: 'KONTAKT SCHAFFT BEWEGUNG',
  showTitle: true,
  submitTxt: 'Anfrage senden',
  template: 'TemplateMain',
  formFieldProps: {
    variant: 'outlined',
  },
  fields: [
    {id: 'name', title: 'Name', required: true, min: 2, max: 100},
    {id: 'email', type: 'email', title: 'E-Mail Adresse', required: true, min: 2, max: 80},
    {id: 'request', type: 'textarea', title: 'Anfrage'},
    {id: 'phone', type: 'callback', consent: {
      text: 'Ich bitte um einen Rückruf',
    }, title: 'Rückruf-Nummer', min: 4, max: 20},
    {id: 'newsletter_consent', title: 'Newsletter', type: 'checkbox', consent: {
      text: 'Ich bin damit einverstanden, auch zukünftig auf Basis meiner Interessen Einladungen zu Veranstaltungen oder interessante Informationen zu erhalten. Diese Zustimmungserklärung kann ich jederzeit widerrufen per E-Mail an office@zimtnow.com.',
      linksTitle: 'Weitere Informationen finde ich in der Datenschutzerklärung im folgenden Link',
      links: [
        {url: 'https://www.zimtnow.com/datenschutz', title: 'Datenschutzerklärung'}
      ]
    }},
  ],
  consent: {
    text: 'Ich akzptiere die Datenschutzbestimmungen der zimtnow GmbH. Das Abschicken dieses Formulars erfolgt in Übereinstimmung mit unseren Compliance-Richtlinien sowie anwendbaren gesetzlichen Vorschriften. Ihre personenbezogenen Daten werden für die Abwicklung Ihrer Anfrage verwendet.',
    linksTitle: 'Mehr Informationen finden Sie unter dem nachfolgenden Link',
    links: [
      {url: 'https://www.zimtnow.com/datenschutz', title: 'Datenschutzerklärung'}
    ]
  }
}

const academyForm = {
  title: 'Anmeldung',
  showTitle: true,
  submitTxt: 'Anmeldung senden',
  template: 'TemplateAcademy',
  formFieldProps: {
    variant: 'outlined',
    InputLabelProps: {shrink: true},
  },
  fields: [
    {id: 'name', title: 'Workshop', type: 'radio', fieldProps: {checked: true, disabled: true}},
    {id: 'name', title: 'Name', required: true, min: 2, max: 100},
    {id: 'email', type: 'email', title: 'E-Mail Adresse', required: true, min: 2, max: 80},
    {id: 'slot', title: 'Termin', type: 'event', calId: '54dc8451-0f87-4575-b9d2-954a0880e259'},
    {id: 'newsletter_consent', step: 6, title: 'Newsletter', type: 'checkbox', consent: {
      text: 'Ich bin damit einverstanden, auch zukünftig auf Basis meiner Interessen Einladungen zu Veranstaltungen oder interessante Informationen zu erhalten. Diese Zustimmungserklärung kann ich jederzeit widerrufen per E-Mail an office@zimtnow.com.',
      linksTitle: 'Weitere Informationen finde ich in der Datenschutzerklärung im folgenden Link',
      links: [
        {url: 'https://www.zimtnow.com/datenschutz', title: 'Datenschutzerklärung'}
      ]
    }},
  ],
  consent: {
    text: 'Ich akzptiere die Datenschutzbestimmungen der zimtnow GmbH. Das Abschicken dieses Formulars erfolgt in Übereinstimmung mit unseren Compliance-Richtlinien sowie anwendbaren gesetzlichen Vorschriften. Ihre personenbezogenen Daten werden für die Abwicklung Ihrer Anfrage verwendet.',
    linksTitle: 'Mehr Informationen finden Sie unter dem nachfolgenden Link',
    links: [
      {url: 'https://www.zimtnow.com/datenschutz', title: 'Datenschutzerklärung'}
    ]
  }
}


const webinarPlanForm = {
  title: 'WEBINAR PLANNER',
  showTitle: true,
  submitTxt: 'Information senden',
  template: 'TemplateMain',
  formFieldProps: {
    variant: 'outlined',
    InputLabelProps: {shrink: true},
  },
  steps: [
    {id: 0, title: 'Willkommen', intro: true, icon: PermDataSettingIcon, nextTxt: 'Start'},
    {id: 1, title: 'Unsere Kontaktperson in Ihrem Unternehmen', next: true, icon: ContactMailIcon},
    {id: 2, title: 'Das Webinar', next: true, back: true, icon: LaptopMacIcon},
    {id: 3, title: 'DiskussionsteilnehmerInnen', next: true, back: true, icon: GroupAddIcon},
    {id: 4, title: 'Webinar-Extras', next: true, back: true, icon: AddToPhotosIcon},
    {id: 5, title: 'Rechnungsadresse', next: true, back: true, icon: ReceiptIcon},
    {id: 6, outro: true, title: 'Information senden', back: true, icon: ReceiptIcon}
  ],
  fields: [
    {type: 'intro', title: 'Webinarplanner', step: 0, content: <div>Damit Ihr Webinar ein <b>voller Erfolg</b> wird, brauchen wir <b>exakte Daten</b>. Sollten einzelne Punkte des Formulars unklar sein oder Ihnen Informationen noch nicht vorliegen, lassen Sie diese einfach aus, um sie an einem <b>späterem Zeitpunkt</b> per Mail an office@zimtnow.com nachzureichen.<br /><br />Vielen Dank und viel Spaß bei der Webinarplanung!<br /></div>},
    {id: 'name', title: 'Name', step: 1, required: true, placeholder: 'Vorname Nachname', min: 2, max: 100},
    {id: 'email', type: 'email', step: 1,  title: 'E-Mail Adresse', required: true, min: 2, max: 80},
    {id: 'phone', title: 'Telefonnummer', step: 1, placeholder: '+43', required: true, min: 4, max: 30},
    {id: 'webinar_title', title: 'Webinar-Titel', step: 2, placeholder: 'z.B.: WEBINAR - Die Zukunft der Menschheit', min: 4, max: 128},
    {id: 'webinar_description', type:'textarea', step: 2, info: 'Dies ist der Beschreibungstext zum Webinar und erscheint, nachdem man auf den Anmeldelink geklickt hat. Wir empfehlen folgenden Inhalt: 1.) Kurzbeschreibung des Webinars 2.) Kurzer Ablaufplan 3.) Namen und Spezialisierung der RednerInnen  4.) Datum des Anmeldeschlusses 5.) Kosten 6.) Kontakt für Rückfragen (Mailadresse etc.)', title: 'Webinar-Beschreibungstext', min: 4, max: 2048},
    {id: 'webinar_email', type: 'email', step: 2, title: 'E-Mail Adresse', info: 'Kontaktadresse für TeilnehmerInnen-Rückfragen', min: 2, max: 80},
    {id: 'webinar_lang', type: 'select', step: 2, title: 'Sprache', options: [{id: 'de', title: 'Deutsch'}, {id: 'en', title: 'Englisch'}, {id: 'misc', title: 'Andere'}]},
    {id: 'webinar_date', title: 'Datum', step: 2, placeholder: '__.__.____', type: 'date'},
    {id: 'webinar_start', title: 'Startzeitpunkt', step: 2, placeholder: '__:__', type: 'time'},
    {id: 'webinar_end', title: 'Endzeitpunkt', step: 2, placeholder: '__:__', type: 'time'},
    {id: 'webinar_size', type: 'select', step: 2, title: 'Raumgröße / Kapazität', info: 'Wählen Sie entsprechend der benötigten Kapazität einen digitalen Raum aus. Bitte beachten Sie, dass ein späteres Upgrade aus technischen Gründen nicht immer möglich ist.', options: [
      {id: '100', title: 'max. 100 Teilnehmer:innen'},
      {id: '500', title: 'max. 500 Teilnehmer:innen'},
      {id: '1000', title: 'max. 1000 Teilnehmer:innen'},
      {id: '9999', title: 'mehr als 1000 Teilnehmer:innen'}
    ]},
    {id: 'speaker', title: 'DiskussionsteilnehmerInnen', type: 'textarea', placeholder: 'Vorname Nachname, email@adresse, Standort', step: 3},
    {id: 'webinar_extra_post', step: 4, title: 'Webinar-Aufzeichnung und Nachbearbeitung für Video On Demand (Youtube, Vimeo... )', type: 'checkbox'},
    {id: 'webinar_extra_stream', step: 4, title: 'Streamen eines Videos während des Webinars', type: 'checkbox'},
    {id: 'company', step: 5, title: 'Unternehmen'},
    {id: 'address1', step: 5, title: 'Adresszeile 1'},
    {id: 'address2', step: 5, title: 'Adresszeile 2'},
    {id: 'postal_code', step: 5, title: 'PLZ'},
    {id: 'city', step: 5, title: 'Stadt/Ort'},
    {id: 'country', step: 5, title: 'Land'},
    {id: 'invoice_note', step: 5, title: 'Zusätzliche Angaben auf der Rechnung', type: 'textarea'},
    {id: 'uid', step: 5, title: 'UID-Nummer' },
    {type: 'overview', title: 'Zusammenfassung', step: 6},
    {id: 'note', step: 6, title: 'Sonstige Anmerkungen oder Fragen an zimtnow', type: 'textarea'},
    {id: 'newsletter_consent', step: 6, title: 'Newsletter', type: 'checkbox', consent: {
      text: 'Ich bin damit einverstanden, auch zukünftig auf Basis meiner Interessen Einladungen zu Veranstaltungen oder interessante Informationen zu erhalten. Diese Zustimmungserklärung kann ich jederzeit widerrufen per E-Mail an office@zimtnow.com.',
      linksTitle: 'Weitere Informationen finde ich in der Datenschutzerklärung im folgenden Link',
      links: [
        {url: 'https://www.zimtnow.com/datenschutz', title: 'Datenschutzerklärung'}
      ]
    }},
  ],
  consent: {
    text: 'Ich akzptiere die Datenschutzbestimmungen der zimtnow GmbH. Das Abschicken dieses Formulars erfolgt in Übereinstimmung mit unseren Compliance-Richtlinien sowie anwendbaren gesetzlichen Vorschriften. Ihre personenbezogenen Daten werden für die Abwicklung Ihrer Anfrage verwendet.',
    linksTitle: 'Mehr Informationen finden Sie unter dem nachfolgenden Link',
    links: [
      {url: 'https://www.zimtnow.com/datenschutz', title: 'Datenschutzerklärung'}
    ]
  }
}

let webinarPlanZFForm = webinarPlanForm;
webinarPlanZFForm.fields[0] = {type: 'intro', title: 'Webinarplanner', step: 0, content: <div>Damit Ihr Webinar ein <b>voller Erfolg</b> wird, brauchen wir <b>exakte Daten</b>. Sollten einzelne Punkte des Formulars unklar sein oder Ihnen Informationen noch nicht vorliegen, lassen Sie diese einfach aus, um sie an einem <b>späterem Zeitpunkt</b> per Mail an office@zimtfilm.com nachzureichen.<br /><br />Vielen Dank und viel Spaß bei der Webinarplanung!<br /></div>};
webinarPlanZFForm.fields[webinarPlanZFForm.fields.length - 1] = {id: 'newsletter_consent', step: 6, title: 'Newsletter', type: 'checkbox', consent: {
  text: 'Ich bin damit einverstanden, auch zukünftig auf Basis meiner Interessen Einladungen zu Veranstaltungen oder interessante Informationen zu erhalten. Diese Zustimmungserklärung kann ich jederzeit widerrufen per E-Mail an office@zimtnow.com.',
  linksTitle: 'Weitere Informationen finde ich in der Datenschutzerklärung im folgenden Link',
  links: [
    {url: 'https://www.zimtfilm.com/datenschutz', title: 'Datenschutzerklärung'}
  ]
}};
webinarPlanZFForm.consent = {
  text: 'Ich akzptiere die Datenschutzbestimmungen von zimtfilm e.U. Das Abschicken dieses Formulars erfolgt in Übereinstimmung mit unseren Compliance-Richtlinien sowie anwendbaren gesetzlichen Vorschriften. Ihre personenbezogenen Daten werden für die Abwicklung Ihrer Anfrage verwendet.',
  linksTitle: 'Mehr Informationen finden Sie unter dem nachfolgenden Link',
  links: [
    {url: 'https://www.zimtfilm.com/datenschutz', title: 'Datenschutzerklärung'}
  ]
};

const colors = {
  main: '46,46,46',
  film: '17,72,94',
  consult: '183,176,151',
  event: '234,79,83',
  backupcab: '235,181,32',
}

export const formData:IFormData[] = [
  {
    ...defaultForm,
    id: 'film',
    color: colors.main,
    member: members.ee
  },{
    ...defaultForm,
    id: 'event',
    color: colors.event,
    member: members.mg
  },{
    ...defaultForm,
    id: 'consult',
    color: colors.consult,
    member: members.mg
  },{
    ...defaultForm,
    title: 'Anfrage an backupcab',
    id: 'backup',
    color: colors.backupcab,
    member: members.mg
  },{
    ...webinarPlanForm,
    id: 'webinar_plan',
    color: colors.event,
    member: members.mg
  },{
    ...webinarPlanZFForm,
    id: 'webinar_plan_zimtfilm',
    color: colors.main,
    member: members.zf
  },{
    ...academyForm,
    id: 'academy',
    color: colors.main,
    member: members.mg
  },{
    ...defaultForm,
    id: 'default',
    color: colors.main,
    member: members.mg
  }
];
