import {createUseStyles} from 'react-jss';
import style from './TemplateMain.style';
import Form from 'components/Form';
import { Avatar } from '@mui/material';
import MailIcon from '@mui/icons-material/MailOutline';
import PhoneIcon from '@mui/icons-material/PhoneIphoneOutlined';
import LocationIcon from '@mui/icons-material/LocationOnOutlined';
import OpenIcon from '@mui/icons-material/QueryBuilder';
import { STORAGE } from 'config';
import { client } from '../../graphql';
import getAvatarsQuery from 'queries/getAvatars';
import { useEffect, useState } from 'react';

const useStyles = createUseStyles(style);

const TemplateMain = ({formInfo}:any) => {
  const classes = useStyles();
  const [avatarsData, setAvatarsData] = useState<any>();


  useEffect(() => {
    const getAvatars = async (list:string[]) => {
      try {
        let newAvatarsData = await client.request(getAvatarsQuery, {ids: list});
        console.log(newAvatarsData)
        if(newAvatarsData?.getAvatars?.res){
          setAvatarsData(newAvatarsData.getAvatars.res);
        }
      } catch (e) {
        console.log(e);
      }
    };
    console.log(formInfo?.member?.avatar)
    if (formInfo?.member?.avatar) getAvatars([formInfo.member.avatar]);
  },[formInfo?.member?.avatar])

  return <div className={classes.contactWrapper}>
      {formInfo && <Form formInfo={formInfo} />}
      {formInfo && formInfo.member && <div className={classes.contactUs}>
        <div className={classes.contactInfo}>
          <ul className={classes.contactList}>
            <li className={classes.contactHl}><Avatar className={classes.avatarIcon} alt={formInfo.member.name} src={STORAGE + avatarsData?.[formInfo.member.avatar] + '?h=160'} style={{width: '160px', height: '160px', border: '4px solid rgba('+formInfo.color+',1)'}} /></li>
            <li><b>{formInfo.member.name}</b></li>
            <li>{formInfo.member.position}</li>
            <li className={classes.contactHl}><Avatar className={classes.contactIcon} style={{color: '#fff', backgroundColor: 'rgba('+formInfo.color+',1)'}}><MailIcon /></Avatar><Avatar className={classes.contactIcon} style={{color: '#fff', backgroundColor: 'rgba('+formInfo.color+',1)'}}><PhoneIcon /></Avatar></li>
            <li><span style={{wordBreak: 'break-all'}}><a href={'mailto:'+formInfo.member.email}>{formInfo.member.email}</a></span></li>
            <li><a href="tel:+4369911070782">+43 699 11 07 07 82</a></li>
          </ul>
        </div>
        <div className={classes.contactInfo}>
          <ul className={classes.contactList}>
            <li className={classes.contactHl}><Avatar className={classes.contactIcon} style={{color: '#fff', backgroundColor: 'rgba('+formInfo.color+',1)'}}><LocationIcon /></Avatar></li>
            <li className={classes.contactHl}><span>Anschrift</span></li>
            <li><b>{formInfo.member.company}</b><br />Lederergasse 20/11<br />1080 Wien</li>
          </ul>
        </div>
        <div className={classes.contactInfo}>
          <ul className={classes.contactList}>
            <li className={classes.contactHl}><Avatar className={classes.contactIcon} style={{color: '#fff', backgroundColor: 'rgba('+formInfo.color+',1)'}}><OpenIcon /></Avatar></li>
            <li className={classes.contactHl}><span>Öffnungszeiten</span></li>
            <li>Unser Büro ist für Sie geöffnet von<br /><b>Mo - Fr 10:00 - 18:00</b><br />Wir bitten um eine vorherige Terminabsprache.</li>
            </ul>
        </div>
      </div>}
    </div>
}

export default TemplateMain;