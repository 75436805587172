import { IFormFields } from 'constants/interfaces';
import { format } from 'date-fns'

// export const formatCurrency = (value:string) => {
//   return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(value);
// }
export const formatToView = (value:any, fieldData:IFormFields) => {
  if (value !== null && value !== undefined)
    switch (fieldData.type){
      case 'date':
        value = value ? format(value, 'MM.dd.yyyy') : '';
        return value;
      case 'time':
        value = value ? format(value, 'HH:mm') : '';
        return value;
      // case 'currency':
      //   return formatCurrency(value);
      case 'number':
        return Number(value);
      case 'checkbox':
        return value ? 'Ja' : 'Nein';
      case 'select':
        let opt = fieldData.options ? fieldData.options.find(o => o.id === value) : null;
        return opt ? opt.title : value;
      case 'textarea':
        let lines = value.split('\n');
        return lines.map((l:string) => <div>{l}</div>);
      default:
        return value
    }
}
