

import React, { useState, FunctionComponent } from 'react';

import { createUseStyles } from 'react-jss';
import style from './Overview.style';
import { IOverviewValue, IFormFields, IStep } from 'constants/interfaces';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { formatToView } from 'formatValues';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ErrorIcon from '@mui/icons-material/Error';

const useStyles = createUseStyles(style);


const Overview: FunctionComponent<any> = ({fields, values, steps}) => {
  const classes = useStyles();
  const [overview, setOverview] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const [expanded, setExpanded] = useState<number | undefined>();

  const getOverview = useCallback(async() => {
    let overviewValues: IOverviewValue[] = [];
    Object.keys(values).forEach(key => {
      if(values[key]) {
        let fld = fields.find((f:IFormFields) => f.id === key);
        if (fld) overviewValues.push({id: fld.id, step: fld.step, title: fld.title, value: formatToView(values[key], fld)});
      }
    });
    setOverview(overviewValues);
    setLoading(false);
  },[fields, values]);

  useEffect(() => {
    getOverview();
  },[getOverview]);


  return <div className={classes.overview}>
  {!loading && steps.filter((s:IStep) => !s.intro && !s.outro).map((s:IStep) => {
    let Icon = s.icon;
    let overviewItems = overview.filter((o: IOverviewValue) => o.step === s.id);
    let hasItems = overviewItems.length > 0;
    return <Accordion key={'ov-'+s.id} expanded={expanded === s.id} onChange={() => setExpanded(expanded === s.id ? undefined : s.id)}>
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <div className={classes.heading}>{s.icon && <Icon />} <span>{s.title}</span></div>
      <div>{!hasItems && <ErrorIcon style={{height: '14px'}} />}</div>
    </AccordionSummary>
    <AccordionDetails>
      <div style={{width: '100%'}}>
      {hasItems ? overviewItems.map((v:IOverviewValue) => <div className={classes.infoBox}>
        <div className={classes.label}>{v.title}</div>
        <div className={classes.info}>{v.value}</div>
      </div>) : <div><ErrorIcon style={{height: '14px'}} /> Es wurden keine Angaben gemacht</div>}
      </div>
    </AccordionDetails>
  </Accordion>
})}
</div>

}

export default Overview;
