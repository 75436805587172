const style = {
    contactWrapper: {
        display: 'flex'
    },
    contactInfo: {
        width: '240px',
        color: 'rgb(85, 85, 85)',
        margin: 0,
        fontSize: '.9rem'
    },
    contactUs: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        borderRadius: '8px',
        flex: 1,
        padding: '16px',
        textAlign: 'center',
        '& a': {
            color: '#999',
            textDecoration: 'none'
        },
        '& a:hover': {
            color: '#555'
        }
    },
    contactIcon: {
        margin: '0 4px 0 0'
    },
    avatarIcon: {
        margin: '0 auto'
    },
    contactHl: {
        fontWeight: 700,
        position: 'relative',
        display: 'flex',
        padding: '4px',
        height: 'auto',
        justifyContent: 'center'
    },
    contactList: {
        color: '#555',
        textAlign: 'left',
        padding: 0,
        listStyle: 'none',
        borderRadius: '4px',
        margin: '8px',
        '& li': {
            textAlign: 'center',
            padding: '4px'
        },
    },
    icon: {
        width: '14px',
        minWidth: '14px',
        height: '14px',
        margin: '0px 4px 0px 0px'
    },
    '@media screen and (max-width: 600px)': {
        contactWrapper: {
            flexDirection: 'column'
        },
        contactUs: {
            width: '100%',
            flexDirection: 'column'
        },
        contactInfo: {
            margin: '16px 0 0',
            width: '100%'
        }
    }
}

export default style;
