const style = {
    formWrapper: {
        width: '100%',
        overflow: 'hidden'
    },
    field: {
        margin: '0 0 24px 0',
    },
    form: {
        width: '100%',
        boxSizing: 'border-box',
        minHeight: '400px',
        padding: '8px 10px 8px'
    },
    consentLinkHl: {
        fontSize: '12px',
        margin: '8px 0 0',
        paddingLeft: '34px'
    },
    consentLinks: {
        margin: 0,
        color: '#555',
        listStyleType: 'square',
        '& li': {
            fontSize: '12px',
            padding: 0,
            marginLeft: '24px'
        },
        '& a': {
            fontSize: '12px',
            color: '#555'
        },
        '& a:hover': {
            textDecoration: 'underline' 
        }
    },
    consent: {
        margin: 0,
        '& .MuiFormControlLabel-label': {
            fontSize: '12px',
            lineHeight: 'normal'
        }
    },
    checkbox: {
        margin: 0
    },
    headline: {
        display: 'flex',
        margin: '42px 0',
        fontSize: '150%',
        '& svg': {
            height: '32px',
            width: '32px',
            margin: '0 16px 0 0'
        }
    },
    checkboxCallback: {
        margin: 0,
        '& .MuiFormControlLabel-label': {
            fontSize: '15px',
            lineHeight: 'normal',
            margin: '0 0 4px',
        }
    },
    btn: {
        '& .MuiButton-label': {
            color: '#fff'
        },
        margin: '16px 0',
        height: '56px'
    },
    btnBk: {
        '& .MuiButton-label': {
            color: '#999'
        },
        margin: '16px 0',
        height: '56px'
    },
    '@media screen and (max-width: 600px)': {
        form: {
            width: '100%',
            maxWidth: '100%',
            minHeight: '100%',
        },
    }
}

export default style;
