const style = {
    pickerWrapper: {
        display: 'flex',
        justifyContent: 'center',
        '& h5': {
            color: 'rgba(0, 0, 0, 0.87)'
        },
        '& .Mui-disabled': {
            backgroundColor: 'transparent !important',
            color: '#555 !important'
        },
        '& .Mui-selected': {
            color: '#fff'
        },
        '& .MuiPickersDay-root': {
            backgroundColor: '#999',
            color: '#fff'
        },
    },
    eventSelected: {
        border: '1px solid #bbb',
        borderRadius: '4px',
        backgroundColor: '#eee',
        color: '#555',
        '& svg': {
            fill: '#555'
        }
    },
    event: {
        backgroundColor: '#fff',
        border: '1px solid #eee',
        borderRadius: '4px',
        color: '#555',
        '& svg': {
            fill: '#999'
        }
    },
    listIcon: {
        width: '30px'
    },
    listItem: {
        padding: '4px 0'
    },
    listItemContent: {
        position: 'relative',
        width: '100%'
    },
    listHead: {
        position: 'absolute',
        top: 0,
        leftx: 0,
        lineHeight: '16px',
        margin: 0,
        padding: 0,
        textAlign: 'left',
        backgroundColor: 'transparent'
    },
    listText: {
        padding: '18px 8px 18px 0',
        fontWeight: 600,
        fontSize: '18px'
    },
    wrapper: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    hl: {
        margin: '12px',
        lineHeight: '32px'
    },
    btn: {
        margin: 'auto 0',
        color: '#555'
    },
    slots: {
        width: '100%',
        flex: 1,
        '& h5': {
            textAlign: 'center',
            margin: '12px 0 0',
            fontWeight: '500',
            fontSize: '16px',
            lineHeight: '24px'
        }
    },
    '@media screen and (max-width: 900px)': {
        pickerWrapper: {
            flexDirection: 'column'
        },
    }
}

export default style;
