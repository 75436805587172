const style = {
    overview: {
        margin: '24px 0'
    },
    heading: {
        display: 'flex',
        fontWeight: 600,
        fontSize: '110%',
        '& span': {
            overflowWrap: 'break-word'
        },
        '& svg': {
            margin: '0 12px 0 0'
        }
    },
    infoBox: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    label: {
        fontSize: '80%',
        color: '#999'
    },
    info: {
        width: '100%',
        minWidth: 0,
        margin: '6px 0',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    }
}

export default style;
